<template>
  <v-container>
    <h3 class="text-center">View Import File's Profile</h3>
    <p></p>
    <v-row>
      <v-col>
        <v-text-field
          v-model="profileData.type"
          label="Profile's Name"
          filled
          readonly
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="profileData.action"
          label="Data Destination Collection"
          filled
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Source Field
            </th>
            <th class="text-left">
              Mapping Criteria
            </th>
            <th class="text-left">
              Destination Field
            </th>
            <th class="text-left">
              Formatter
            </th>
            <th class="text-left">
              Keep only
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in profileData.mappingConfig"
            :key="item.name"
          >
            <td>{{ item.srcColumnName }}</td>
            <td>{{ item.action }}</td>
            <td>{{ item.destField }}</td>
            <td>{{ item.formatter }}</td>
            <td>{{ item.keepValue }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p></p>
    <v-row
      align="center"
      justify="space-around"
    >
      <v-btn
        tile
        color="primary"
        @click="editProfile(profileData)"
      >
        <v-icon left>
          {{icons.mdiPencil}}
        </v-icon>
        Edit
      </v-btn>
    </v-row>
    <p></p>
    <h3>Preview CSV File Data With Profile Config</h3>
    <v-row>
      <v-col cols="6">
        <v-file-input
          accept=".txt,.csv"
          label="Import (.txt,.csv)"
          show-size
          clearable
          @change="onFileSelected"
        >
        </v-file-input>
      </v-col>
    </v-row>
    <template>
    <v-data-table
      :headers="headers"
      :items="previewData"
      :hide-default-footer="true"
      disable-pagination
      class="elevation-1"
    ></v-data-table>
  </template>
  </v-container>
</template>

<script>
import LineNavigator from 'line-navigator';
import { mdiPencil } from '@mdi/js';
import { clients } from '../../util/clients';

const initdata = () => ({
  icons: {
    mdiPencil,
  },
  headers: [],
  previewData: [],
});

export default {
  name: 'ViewImportFilesProfile',
  data: () => initdata(),
  components: {
  },
  computed: {
    profileData() {
      return this.$store.state.Bulk.profileData;
    },
  },
  created() {
    const profileID = this.$route.params.id;
    this.$store.dispatch('Bulk/fetchProfileByID', profileID);
  },
  methods: {
    editProfile(item) {
      this.$router.push({ name: 'Configure an Import Profile', params: { id: item.id } });
    },
    onFileSelected(file) {
      const self = this;
      const transformUrl = `/transform?type=csv-parser-pipeline&profile=${this.profileData.type}`;
      // eslint-disable-next-line no-undef
      const navigator = new LineNavigator(file, {
        encoding: 'utf8',
        chunkSize: 1024 * 4,
      });

      const firstIndex = 0;
      let headerValues = '';
      let chunkId = 0;
      const profileData = { ...this.profileData };
      self.headers = [];
      self.previewData = [];
      navigator.readSomeLines(0,
        async function linesReadHandler(err, index, lines, isEof) {
          if (err) self.flashError(`Chunk Id ${chunkId} => ${err}`);
          chunkId += 1;
          if (!profileData.noheader) {
            if (chunkId === 1) {
              headerValues = lines[firstIndex];
            } else {
              lines.unshift(headerValues);
            }
          }
          let response;
          try {
            response = await clients.direct.backendRest.post(
              transformUrl, lines.join('\r\n'),
            );
          } catch (error) {
            return clients.handleAxiosErrors(error);
          }
          let responseData = response.data;
          if (typeof responseData !== 'string') {
            responseData = JSON.stringify(responseData);
          }
          const upserts = JSON.parse(`[${responseData}]`)
            .filter((upsert) => Object.keys(upsert).length > 0);
          if (chunkId === 1) {
            Object.keys(upserts[firstIndex]).forEach((x) => self.headers.push(
              { text: x, value: x },
            ));
          }
          self.previewData = [...self.previewData, ...upserts];

          if (!isEof) {
            navigator.readSomeLines(index + lines.length, linesReadHandler);
          }
          return chunkId;
        });
    },
  },
};
</script>
